import styled from '@emotion/styled';
import {Header} from '../../compontents/Header';
import React, {useEffect, useRef, useState} from 'react';
import Modal from '../../compontents/Modal';
import ImageUploader from 'react-image-upload';
import axios from 'axios';
import axiosInstance from '../../library/axios-index';
import {HOST_URL} from '../../bin/env';
import {useForm} from 'react-hook-form';
import SearchAddress from '../../compontents/SearchAddress';
import {BlockScrolling, checkPasswordRule, checkRole} from '../../bin/common';
import {useLocation, useNavigate} from 'react-router-dom';
import AxiosNavigate from '../../library/axios-navigate';
import {useCookies} from 'react-cookie';
import {CompanyPlan, RoleType} from '../../bin/enum';
import PlanSelectModal from '../../compontents/PlanSelectModal';
import {useRecoilState} from 'recoil';
import {companyState} from '../../library/recoil/atoms/companyState';
import {checkBizRegNum, checkCorpRegNum} from '../../library/company-api';
import ButtonComponent from '../../compontents/Button';
import ModifyPhoneModal from './ModifyPhoneModal';
import {verifyUserState} from '../../library/recoil/atoms/verifyUserState';

const planLabels = {
    'basic': {
        corp_nm: true,
        ceo_nm: true,
        addr: true,
        biz_reg_num: false,
        corp_reg_num: false,
        biz_type: false,
        biz_sector: false,
        fax_num: false,
        phone_num: true,
        manager_nm: true,
        email_addr: false,
        manager_phone_num: true,
        biz_reg_img: false,
        stamp_img: true,
        logo_img: false,

    }, 'premium_enterprise': {
        corp_nm: true,
        ceo_nm: true,
        addr: true,
        biz_reg_num: true,
        corp_reg_num: true,
        biz_type: true,
        biz_sector: true,
        fax_num: false,
        phone_num: true,
        manager_nm: true,
        email_addr: true,
        manager_phone_num: true,
        biz_reg_img: true,
        stamp_img: true,
        logo_img: false,
    }
};


function SettingCompany() {
    const [companyInformation, setCompanyInformation] = useRecoilState(companyState);
    const [info, setInfo] = useRecoilState(verifyUserState);
    // const setCompanyInformation = useSetRecoilState(companyState);
    // const companyInformation = useRecoilValue(companyState);
    const navigate = useNavigate();
    const formRef = useRef();
    const allFormRef = useRef();
    const location = useLocation();
    const [cookies] = useCookies(['role']);

    const [opendNewTeam, setOpendNewTeam] = useState(false);
    const [opendNewRank, setOpendNewRank] = useState(false);
    const [opendModifyPhone, setOpendModifyPhone] = useState(false);

    const [tabName, setTabName] = useState('general');

    const [teams, setTeams] = useState([]);
    const [ranks, setRanks] = useState([]);

    const [addr, setAddr] = useState('');
    const [zoneCode, setZoneCode] = useState('');
    const [logitude, setLogitude] = useState('');
    const [latitude, setLatitude] = useState('');

    const [currentPassword, setCurrentPassword] = useState('');
    const [changedPassword, setChangedPassword] = useState('');
    const [checkChangedPassword, setCheckChangedPassword] = useState('');
    const [planInfo, setPlanInfo] = useState({planId: null, planName: null});
    const [openPlanSelect, setOpenPlanSelect] = useState(false);

    const modalClose = useRef(false);
    const adminRole = cookies.role.admin;
    // const adminRole = checkRole(location.pathname, cookies.role, RoleType.WRITE);
    const {
        register,
        createHandleSubmit,
        handleSubmit,
        getValues,
        setValue,
        setFocus,
        watch,
        reset,
        formState: {errors}
    } = useForm({
        defaultValues: async () => {

            const token = localStorage.getItem('admin_token');

            const {data: {result}} = await axiosInstance.post(`${HOST_URL}/company/info`, {
                lang: 'ko',
                uuid: 'string',
                token
            });

            setAddr(result.addr1);
            // 사업자 등록번호 세팅 3/2/5글자
            result['biz_reg_num1'] = result?.biz_reg_num?.slice(0, 3);
            result['biz_reg_num2'] = result?.biz_reg_num?.slice(3, 5);
            result['biz_reg_num3'] = result?.biz_reg_num?.slice(5, result.biz_reg_num.length);

            // 법인 등록번호 세팅 6/7글자
            result['corp_reg_num1'] = result?.corp_reg_num?.slice(0, 6);
            result['corp_reg_num2'] = result?.corp_reg_num?.slice(6, result.corp_reg_num.length);

            // 우편번호, 위도, 경도 세팅
            setLogitude(result?.logitude);
            setLatitude(result?.latitude);
            setZoneCode(result?.postal_cd);

            //플랜 정보

            setPlanInfo({planId: result.plan_id, planName: result.planName});
            delete result.planId;
            delete result.planName;


            // 모든 입력값의 앞뒤 공백 제거 및 빈 값은 ''로  변경
            const processedData = Object.keys(result).reduce((acc, key) => {
                const trimmedValue = typeof result[key] === 'string' ? result[key].trim() : result[key];
                acc[key] = trimmedValue === null || trimmedValue === undefined ? '' : trimmedValue;
                return acc;
            }, {});

            setCompanyInformation(processedData);

            return result;

        }
    });


    // 숫자만 입력
    const numberOnly = (e) => {
        if (e.target.value.match(/[^0-9]/g)) {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }
    };

    BlockScrolling(openPlanSelect);

    const handleAddrAndZoneCode = (fullAddr, zoneCode = '') => {
        setAddr(fullAddr);
        setZoneCode(zoneCode);
    };

    const handleLogitudeAndLatitude = (logitude, latitude) => {
        setLogitude(logitude);
        setLatitude(latitude);
    };

    const handleUploadFile = async (formName, e) => {

        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }

        const formData = new FormData();
        formData.append('files', e.file);

        const {data: {result}} = await axios({
            method: 'post',
            url: `${HOST_URL}/upload`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        const img = result[0];

        setValue(formName, img);

    };

    const handleDeleteFile = async (formName) => {
        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }

        setValue(formName, '');
    };
    const onError = (errors) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstError = errorKeys[0];
            setFocus(firstError);
            if (errors[firstError].type == 'noSpaces') {
                // reset({[firstError]: ''});
                setValue(firstError, '');
            }
            alert(errors[firstError]?.message || 'Form validation error');
        }

    };
    const onSubmit = async (e) => {
        const corpRegNum = e.corp_reg_num1 + e.corp_reg_num2;
        const bizRegNum = e.biz_reg_num1 + e.biz_reg_num2 + e.biz_reg_num3;

        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            reset();
            return;
        }

        if (!addr) {
            alert('사업장 소재지를 입력해 주세요');
            return;
        }

        if (planInfo.planId !== 'M0015D0001') {

            if (!e.biz_reg_img) {
                alert('사업자등록증을 등록해 주세요');
                return;
            }

        } else {

            if (corpRegNum.length !== 0 && corpRegNum.length < 13) {
                setFocus('corp_reg_num1');
                alert('법인등록번호를 입력해 주세요');
                return;
            }

            if (bizRegNum.length !== 0 && bizRegNum.length < 10) {
                setFocus('biz_reg_num1');
                alert('사업자등록번호를 입력해 주세요');
                return;
            }
        }

        // 중복된 법인 등록번호 체크
        if (corpRegNum !== '' && corpRegNum !== companyInformation?.corp_reg_num) {
            if (await checkCorpRegNum(corpRegNum)) {
                setFocus('corp_reg_num1');
                alert('중복된 법인등록번호가 존재합니다');
                return;
            }
        }

        // 중복된 사업자등록번호 체크
        if (bizRegNum !== '' && bizRegNum !== companyInformation?.biz_reg_num) {
            if (await checkBizRegNum(bizRegNum)) {
                setFocus('biz_reg_num1');
                alert('중복된 사업자등록번호가 존재합니다');
                return;
            }
        }


        if (!e.stamp_img) {
            alert('법인인감을 등록해 주세요');
            return;
        }

        const objectTrim = {
            corp_seq: e.corp_seq,
            corp_nm: e.corp_nm ? e.corp_nm.trim() : null,
            ceo_nm: e.ceo_nm ? e.ceo_nm.trim() : null,
            biz_division: e.biz_division,
            biz_reg_num: e.biz_reg_num1 + e.biz_reg_num2 + e.biz_reg_num3,
            corp_reg_num: e.corp_reg_num1 + e.corp_reg_num2,
            biz_type: e.biz_type ? e.biz_type.trim() : null,
            biz_sector: e.biz_sector ? e.biz_sector.trim() : null,
            phone_num: e.phone_num,
            fax_num: e.fax_num,
            addr1: addr,
            addr2: e.addr2 ? e.addr2.trim() : null,
            postal_cd: zoneCode,
            logitude: logitude,
            latitude: latitude,
            manager_nm: e.manager_nm ? e.manager_nm.trim() : null,
            email_addr: e.email_addr ? e.email_addr.trim() : null,
            //manager_phone_num: '',
            manager_phone_num: e.manager_phone_num,
            logo_img: e.logo_img,
            stamp_img: e.stamp_img,
            biz_reg_img: e.biz_reg_img,
            //plan_id: 'M0015D0002'
        };

        try {

            const token = localStorage.getItem('admin_token');

            const {data: {result}} = await axiosInstance.post(`${HOST_URL}/company/modifyInfo`, {
                lang: 'ko',
                uuid: 'string',
                token,
                ...objectTrim
            });

            if (result.done) {

                setCompanyInformation(objectTrim);
                reset(objectTrim);
                alert('회사정보가 수정되었습니다');
                setInfo(prevState => ({...prevState, corp_nm: e.corp_nm.trim(), manager_nm: e.manager_nm.trim()}));
            }

        } catch ({response: {data: {message}}}) {
            alert(message);
        }

    };

    const handleNewTeam = async () => {

        const inputs = Array.from(document.querySelectorAll('.new-team-form input'));

        if (inputs.find(e => e.value === '')) {
            alert('필수 입력 항목이 비어있습니다');
            modalClose.current = true;
            return;
        }
        modalClose.current = false;
        const token = localStorage.getItem('admin_token');

        const {data: {result: done}} = await axiosInstance.post(`/company/newTeam`, {
            lang: 'ko',
            uuid: 'string',
            token,
            team_order: inputs.find(e => e.name === 'team_order').value,
            team_nm: inputs.find(e => e.name === 'team_nm').value
        });
        await loadTeamAndList();
    };

    const handleNewRank = async () => {

        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }

        const inputs = Array.from(document.querySelectorAll('.new-rank-form input'));

        if (inputs.find(e => e.value === '')) {
            alert('필수 입력 항목이 비어있습니다');
            modalClose.current = true;
            return;
        }
        modalClose.current = false;
        const token = localStorage.getItem('admin_token');

        const {data: {result: done}} = await axiosInstance.post(`/company/newRank`, {
            lang: 'ko',
            uuid: 'string',
            token,
            rank_order: inputs.find(e => e.name === 'rank_order').value,
            position_nm: inputs.find(e => e.name === 'position_nm').value
        });

        await loadTeamAndList();

    };

    const handleDeleteTeam = async (team_seq) => {

        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }

        if (!window.confirm('정말로 삭제하시겠습니까?')) return;

        const token = localStorage.getItem('admin_token');
        try {
            await axiosInstance.post(`/company/deleteTeam`, {
                lang: 'ko',
                uuid: 'string',
                token,
                team_seq
            });
        } catch (e) {
            return alert(e.response.data.message);
        }

        await loadTeamAndList();
    };

    const handleDeleteRank = async (rank_seq) => {

        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }

        if (!window.confirm('정말로 삭제하시겠습니까?')) return;

        const token = localStorage.getItem('admin_token');

        try {
            await axiosInstance.post(`/company/deleteRank`, {
                lang: 'ko',
                uuid: 'string',
                token,
                rank_seq
            });
        } catch (e) {
            return alert(e.response.data.message);
        }

        await loadTeamAndList();
    };

    const handleChangePassword = async () => {

        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }

        const token = localStorage.getItem('admin_token');

        if (changedPassword !== checkChangedPassword) {
            alert('비밀번호가 일치하지 않습니다');
            return;
        }

        if (checkPasswordRule(changedPassword)) {
            alert('비밀번호는 8~15 자리 영문,숫자,특수문자를 포함해야 합니다.');
            return;
        }

        const data = {
            token,
            currentPassword,
            changedPassword,
        };

        try {
            await axiosInstance.post(`/company/changePassword`, data);
            localStorage.setItem('admin_token', '');
            navigate('/login');
            alert('비밀번호가 변경 되었습니다. 다시 로그인 해주세요');
        } catch (e) {
            return alert(e.response.data.message);
        }

    };

    const loadTeamAndList = async () => {

        const token = localStorage.getItem('admin_token');

        const teamsResp = await axiosInstance.post(`/company/listTeam`, {
            lang: 'ko',
            uuid: 'string',
            token
        });

        setTeams(teamsResp.data.result);

        const ranksResp = await axiosInstance.post(`/company/listRank`, {
            lang: 'ko',
            uuid: 'string',
            token
        });

        setRanks(ranksResp.data.result);

    };

    useEffect(() => {

        (async () => {

            await loadTeamAndList();

        })();

    }, []);

    useEffect(() => {
        if (tabName === 'general') {
            reset();
        }
    }, [tabName]);

    const requiredLabel = (keyName) => {
        if (!keyName) return;
        const plan = planInfo?.planName === '베이직' ? 'basic' : 'premium_enterprise';

        if (planLabels[plan][keyName]) {
            return (<span>*</span>);
        }
    };

    const handleResetPhone = (value) => {
        //  console.log(value);

        setValue('manager_phone_num', value);
        setOpendModifyPhone(false);

    };
    return (
        <AxiosNavigate>
            <div>
                <Header style={{justifyContent: 'flex-start', alignItems: 'center'}}>
                    회사정보
                    <Badge style={{
                        width: '100px',
                        height: '34px',
                        marginLeft: '20px',
                        marginRight: '10px',
                        fontSize: '14px'
                    }}>{planInfo?.planName}</Badge>
                    {adminRole ? (<Button style={{
                        width: '120px',
                        height: '34px',
                        background: '#fff',
                        color: '#3279F5',
                        border: 'solid 1px #3279F5'
                    }}
                                          onClick={() => {
                                              if (planInfo.planId !== CompanyPlan.ENTERPRISE) {
                                                  setOpenPlanSelect(true);
                                              } else {
                                                  alert('엔터프라이즈 플랜 변경은 관리자에게 문의해 주세요.');
                                              }
                                          }}>등급 변경하기</Button>) : ''}
                </Header>
                <div>

                    <Tabs>
                        <Tab selected={tabName === 'general'} onClick={() => setTabName('general')}>일반사항</Tab>
                        {planInfo.planId != CompanyPlan.BASIC ? <Tab selected={tabName === 'organization'}
                                                                     onClick={() => setTabName('organization')}>조직관리</Tab> :
                            <div/>}
                        <Tab selected={tabName === 'changePassword'} onClick={() => setTabName('changePassword')}>비밀번호
                            변경</Tab>

                    </Tabs>
                </div>

                {tabName === 'general' && <Form ref={allFormRef} onSubmit={handleSubmit(onSubmit, onError)}>
                    <Footer>
                        <ButtonComponent style={{width: '90px', fontsize: '14px', height: '34px', marginTop: '-65px'}}
                                         type="submit">수정하기</ButtonComponent>
                    </Footer>
                    <Field>
                        <Text>법인명 {requiredLabel('corp_nm')}</Text>
                        <Input {...register('corp_nm', {
                            validate: {
                                register: (value) => value !== '' || '법인명을 입력해 주세요',
                                noSpaces: (value) => value.trim() !== '' || '법인명을 입력해 주세요'
                            }
                        })} />
                    </Field>

                    <Field>
                        <Text>대표이사명 {requiredLabel('ceo_nm')}</Text>
                        <Input {...register('ceo_nm', {
                            validate: {
                                register: (value) => value !== '' || '대표이사명을 입력해 주세요',
                                noSpaces: (value) => value.trim() !== '' || '대표이사명을 입력해 주세요'
                            }
                        })}/>
                    </Field>

                    <Field>
                        <Text>사업자 구분 </Text>
                        <label>
                            <input value="C" type="radio" {...register('biz_division')}/>
                            법인
                        </label>
                        <label>
                            <input value="P" type="radio" {...register('biz_division')}/>
                            개인
                        </label>
                    </Field>

                    <Field>
                        <Text>사업자등록번호 {requiredLabel('biz_reg_num')}</Text>
                        <Fields style={{display: 'flex', flexDirection: 'row',}}>
                            <Field style={{width: '30%', alignItems: 'center'}}>
                                <Input style={{marginRight: '12px'}} {...register('biz_reg_num1', {
                                    validate: planInfo?.planId !== 'M0015D0001' ? {
                                        register: (value) => value.length >= 3 || '사업자등록번호를 입력해 주세요',
                                    } : {
                                        register: (value) => value.length === 0 || value.length >= 3 || '사업자등록번호를 입력해 주세요',
                                    }
                                })} maxLength={3}
                                       onChange={(e) => {
                                           numberOnly(e);

                                           if (e.target.value.length >= e.target.maxLength) {
                                               setFocus('biz_reg_num2');
                                           }
                                       }}/>
                            </Field>
                            <Field style={{width: '20%', alignItems: 'center'}}>
                                <Input style={{marginRight: '12px'}} {...register('biz_reg_num2', {
                                    validate: planInfo?.planId !== 'M0015D0001' ? {
                                        register: (value) => value.length >= 2 || '사업자등록번호를 입력해 주세요',
                                    } : {
                                        register: (value) => value.length === 0 || value.length >= 2 || '사업자등록번호를 입력해 주세요',
                                    }
                                })} maxLength={'2'}
                                       onChange={(e) => {
                                           numberOnly(e);
                                           if (e.target.value.length >= e.target.maxLength) {
                                               setFocus('biz_reg_num3');
                                           }
                                       }}
                                />
                            </Field>
                            <Field style={{width: '44%', alignItems: 'center'}}>
                                <Input {...register('biz_reg_num3', {
                                    validate: planInfo?.planId !== 'M0015D0001' ? {
                                        register: (value) => value.length >= 5 || '사업자등록번호를 입력해 주세요',
                                    } : {
                                        register: (value) => value.length === 0 || value.length >= 5 || '사업자등록번호를 입력해 주세요',
                                    }
                                })} maxLength={'5'}
                                       onChange={(e) => {
                                           numberOnly(e);
                                           if (e.target.value.length >= e.target.maxLength) {
                                               const nextInput = e.target.nextElementSibling;
                                               if (nextInput) {
                                                   nextInput.focus();
                                               }
                                           }
                                       }}
                                />
                            </Field>
                        </Fields>
                    </Field>


                    <Field>
                        <Text>법인등록번호 {requiredLabel('corp_reg_num')}</Text>
                        <Fields style={{display: 'flex', flexDirection: 'row',}}>
                            <Field style={{width: '47.5%', alignItems: 'center'}}>
                                <Input style={{marginRight: '12px'}} {...register('corp_reg_num1', {
                                    validate: planInfo?.planId !== 'M0015D0001' ? {
                                        register: (value) => value.length >= 6 || 'c',
                                    } : {
                                        register: (value) => value.length === 0 || value.length >= 6 || '법인등록번호를 입력해 주세요',

                                    }
                                })}
                                       maxLength={'6'}
                                       onChange={(e) => {
                                           numberOnly(e);
                                           if (e.target.value.length >= e.target.maxLength) {
                                               setFocus('corp_reg_num2');
                                           }
                                       }}
                                />
                            </Field>
                            <Field style={{width: '47.5%', alignItems: 'center'}}>
                                <Input {...register('corp_reg_num2', {
                                    validate: planInfo?.planId !== 'M0015D0001' ? {
                                        register: (value) => value.length >= 7 || '법인등록번호를 입력해 주세요',
                                    } : {
                                        register: (value) => value.length === 0 || value.length >= 7 || '법인등록번호를 입력해 주세요',
                                    }
                                })}
                                       maxLength={'7'}
                                       onChange={(e) => {
                                           numberOnly(e);
                                           if (e.target.value.length >= e.target.maxLength) {
                                               const nextInput = e.target.nextElementSibling;
                                               if (nextInput) {
                                                   nextInput.focus();
                                               }
                                           }
                                       }}
                                />
                            </Field>
                        </Fields>
                    </Field>

                    <Field/>

                    <Field>
                        <Text>업태 {requiredLabel('biz_type')}</Text>
                        <Input {...register('biz_type', {
                            validate: planInfo?.planId !== 'M0015D0001' ? {
                                register: (value) => value !== '' || '업태를 입력해 주세요',
                                noSpaces: (value) => value?.trim() !== '' || '업태를 입력해 주세요'
                            } : {}
                        })}/>
                    </Field>

                    <Field>
                        <Text>종목 {requiredLabel('biz_sector')}</Text>
                        <Input {...register('biz_sector', {
                            validate: planInfo?.planId !== 'M0015D0001' ? {
                                register: (value) => value !== '' || '종목을 입력해 주세요',
                                noSpaces: (value) => value?.trim() !== '' || '종목을 입력해 주세요'
                            } : {}
                        })}/>
                    </Field>

                    <Field></Field>
                    <Field>
                        <Text>대표전화 {requiredLabel('phone_num')}</Text>
                        <Input {...register('phone_num', {
                            validate: {
                                register: (value) => value?.length >= 8 || '대표전화를 입력해 주세요',
                                // noSpaces: (value) => /^(01[016789]{1})?[0-9]{3,4}?[0-9]{4}$/.test(value.trim()) || '대표전화 형식이 올바르지 않습니다.'
                            }
                        })}
                               fulled type="text"
                               maxLength={11}
                               onChange={(e) => {
                                   numberOnly(e);
                               }}/>
                    </Field>

                    <Field>
                        <Text>팩스번호 {requiredLabel('fax_num')}</Text>
                        <Input {...register('fax_num')}
                               fulled type="text"
                               maxLength={11}
                               onChange={(e) => {
                                   numberOnly(e);
                               }}/>
                    </Field>

                    <div style={{width: '99%'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div style={{width: '50%', marginRight: '12px', display: 'flex', alignItems: 'center'}}>
                                <Text style={{marginRight: '12px', marginTop: '12px'}}>사업장
                                    소재지 {requiredLabel('addr')}</Text>
                                <SearchAddress
                                    setAddrAndZoneCode={handleAddrAndZoneCode}
                                    setLongitudeAndLatitude={handleLogitudeAndLatitude}
                                />
                            </div>
                            <Text style={{marginTop: '12px'}}>상세 주소</Text>
                        </div>
                        <Input style={{width: '50%', marginRight: '12px'}} {...register('addr1')} readOnly={true}
                               value={addr}/>
                        <Input style={{width: '40%'}}{...register('addr2')}/>
                    </div>

                    <Field>
                    </Field>
                    <Fields style={{width: '200%'}}>
                        <Field>
                            <Text>이름(관리자) {requiredLabel('manager_nm')}</Text>
                            <Input {...register('manager_nm', {
                                validate: {
                                    register: (value) => value !== '' || '이름(관리자)를 입력해 주세요',
                                    noSpaces: (value) => value?.trim() !== '' || '이름(관리자)를 입력해 주세요'
                                }
                            })}/>
                        </Field>
                        <Field>
                            <Text>이메일 {requiredLabel('email_addr')}</Text>
                            <Input {...register('email_addr', {
                                validate: planInfo?.planId !== 'M0015D0001' ? {
                                    register: (value) => value !== '' || '이메일을 입력해 주세요',
                                    noSpaces: (value) => value?.trim() !== '' || '이메일을 입력해 주세요'
                                } : {}, pattern: {
                                    value: /^[ A-z0-9]{2,20}@[A-z]{2,20}\.[a-z]{2,10}$/,
                                    message: '이메일 형식이 올바르지 않습니다.'
                                }
                            })}/>
                        </Field>

                        <Field>
                            <Text>휴대폰번호(관리자) {requiredLabel('manager_phone_num')}</Text>
                            <div style={{display: 'flex', columnGap: '20px'}}>
                                <Input {...register('manager_phone_num', {
                                    validate: {
                                        register: (value) => value.length >= 11 || '휴대폰번호(관리자)를 입력해 주세요',
                                        // noSpaces: (value) => /^(01[016789]{1})?[0-9]{3,4}?[0-9]{4}$/.test(value.trim()) || '휴대폰번호(관리자) 형식이 올바르지 않습니다.'
                                    }, pattern: {
                                        value: /^(01[016789]{1})?[0-9]{3,4}?[0-9]{4}$/,
                                        message: '휴대폰번호(관리자) 형식이 올바르지 않습니다.'
                                    }
                                })}
                                       fulled type="text"
                                       maxLength={11}
                                       onChange={(e) => {
                                           numberOnly(e);
                                       }}
                                       readOnly={true}
                                />
                                <ButtonComponent onClick={event => setOpendModifyPhone(true)}
                                                 type="button"
                                                 style={{
                                                     backgroundColor: '#708090',
                                                     height: '37px',
                                                     width: '90px',
                                                     fontSize: '16px',
                                                     background: '#569674'
                                                 }}>재인증</ButtonComponent>
                            </div>
                        </Field>
                    </Fields>

                    <Card>

                        <Title><Icon src="/assets/icons/alert-circle.svg"/> 회사로고와 인감 이미지는 근로계약서 등의 양식에 필수사항입니다</Title>

                        <Fields imgBox={'true'}>

                            <Uploader>
                                <Text>회사로고 {requiredLabel('logo_img')}</Text>
                                <ImageBox>
                                    <ImageUploader
                                        onFileRemoved={() => {
                                            if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                                handleDeleteFile('logo_img');
                                            }
                                        }}
                                        onFileAdded={(e) => {
                                            if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                                handleUploadFile('logo_img', e);
                                            }
                                        }}
                                        deleteIcon={watch('logo_img') ? <ImageDelete className="delete-label">
                                            <ImageButton style={{color: '#E53E3E'}}><Icon
                                                src="/assets/icons/trash-04.svg"/> 삭제</ImageButton>
                                        </ImageDelete> : <div></div>}
                                        uploadIcon={!watch('logo_img') ? <>
                                            <ImagePreview>
                                                <img src="/assets/icons/image-03.svg" alt={'upload'}/>
                                                <ImageButton>이미지 업로드</ImageButton>
                                            </ImagePreview>
                                            <br/>
                                        </> : <>
                                            <ImageWrapper className="upload-label">
                                                <ImageButton>이미지 재업로드</ImageButton>
                                            </ImageWrapper>
                                        </>}
                                    />
                                    <ImageDefault src={watch('logo_img')}
                                                  style={getValues('logo_img') ? {width: 'inherit'} : {width: 'unset'}}/>

                                    {watch('logo_img') && <div className="hover-wrapper"></div>}
                                </ImageBox>
                            </Uploader>

                            <Uploader>
                                <Text>법인인감 이미지 {requiredLabel('stamp_img')}</Text>
                                <ImageBox>
                                    <ImageUploader
                                        onFileRemoved={() => handleDeleteFile('stamp_img')}
                                        onFileAdded={(e) => handleUploadFile('stamp_img', e)}
                                        deleteIcon={watch('stamp_img') ? <ImageDelete className="delete-label">
                                            <ImageButton style={{color: '#E53E3E'}}><Icon
                                                src="/assets/icons/trash-04.svg"/> 삭제</ImageButton>
                                        </ImageDelete> : <div></div>}
                                        uploadIcon={!watch('stamp_img') ? <>
                                            <ImagePreview>
                                                <img src="/assets/icons/image-03.svg" alt={'upload'}/>
                                                <ImageButton>이미지 업로드</ImageButton>
                                            </ImagePreview>
                                            <br/>
                                        </> : <>
                                            <ImageWrapper className="upload-label">
                                                <ImageButton>이미지 재업로드</ImageButton>
                                            </ImageWrapper>
                                        </>}
                                    />
                                    <ImageDefault src={watch('stamp_img')}
                                                  style={getValues('stamp_img') ? {width: 'inherit'} : {width: 'unset'}}
                                    />
                                    {watch('stamp_img') && <div className="hover-wrapper"></div>}
                                </ImageBox>
                            </Uploader>

                            <Uploader>
                                <Text>사업자등록증 이미지 {requiredLabel('biz_reg_img')}</Text>
                                <ImageBox>
                                    <ImageUploader
                                        onFileRemoved={() => handleDeleteFile('biz_reg_img')}
                                        onFileAdded={(e) => handleUploadFile('biz_reg_img', e)}
                                        deleteIcon={watch('biz_reg_img') ? <ImageDelete className="delete-label">
                                            <ImageButton style={{color: '#E53E3E'}}><Icon
                                                src="/assets/icons/trash-04.svg"/> 삭제</ImageButton>
                                        </ImageDelete> : <div></div>}
                                        uploadIcon={!watch('biz_reg_img') ? <>
                                            <ImagePreview>
                                                <img src="/assets/icons/image-03.svg" alt={'upload'}/>
                                                <ImageButton>이미지 업로드</ImageButton>
                                            </ImagePreview>
                                            <br/>
                                        </> : <>
                                            <ImageWrapper className="upload-label">
                                                <ImageButton>이미지 재업로드</ImageButton>
                                            </ImageWrapper>
                                        </>}
                                    />
                                    <ImageDefault src={watch('biz_reg_img')}
                                                  style={getValues('biz_reg_img') ? {width: 'inherit'} : {width: 'unset'}}
                                                  onerror="this.style.display='none'"/>
                                    {watch('biz_reg_img') && <div className="hover-wrapper"></div>}
                                </ImageBox>
                            </Uploader>

                        </Fields>
                    </Card>


                </Form>}

                {tabName === 'organization' && <Organization>

                    <Board>
                        <BoardHeader>
                            <h3>부서 관리</h3>
                            <Button onClick={() => {
                                if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                    setOpendNewTeam(true);
                                }
                            }}>부서추가</Button>
                        </BoardHeader>

                        <TableContainer>
                            <TableWrapper>
                                <Table>
                                    <thead>

                                    <tr>
                                        <th>코드</th>
                                        <th>부서명</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        teams.map((e, i) => <tr key={i}>
                                            <td>{e.team_order}</td>
                                            <td><Td>{e.team_nm} <TdButton onClick={() => {
                                                if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                                    handleDeleteTeam(e.team_seq);
                                                }
                                            }}>삭제</TdButton></Td></td>
                                        </tr>)
                                    }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </TableContainer>

                    </Board>

                    <Board>
                        <BoardHeader>
                            <h3>직급 관리</h3>
                            <Button onClick={() => {
                                if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                    setOpendNewRank(true);
                                }
                            }}>직급추가</Button>
                        </BoardHeader>

                        <TableContainer>
                            <TableWrapper>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>코드</th>
                                        <th>직급명</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        ranks.map((e, i) => <tr key={i}>
                                            <td>{e.rank_order}</td>
                                            <td><Td>{e.position_nm} <TdButton onClick={() => {
                                                if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                                    handleDeleteRank(e.rank_seq);
                                                }
                                            }}>삭제</TdButton></Td></td>
                                        </tr>)
                                    }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </TableContainer>

                    </Board>

                </Organization>}

                {tabName === 'changePassword' && <Organization>

                    <Board>
                        <BoardHeader style={{display: 'grid', gridTemplateColumns: '2fr 60px 1fr 1fr'}}>
                            <h3>비밀번호 변경</h3>
                            <Button onClick={() => handleChangePassword()}>변경</Button>
                        </BoardHeader>
                        <Field style={{width: '55%'}}>
                            <Text>현재 비밀번호</Text>
                            <Input readOnly={false} fulled type="password"
                                   value={currentPassword} maxLength={'15'} onChange={(e) => {
                                setCurrentPassword(e.target.value);
                            }}/>

                            <Text>바꿀 비밀번호</Text>
                            <Input readOnly={false} fulled type="password"
                                   value={changedPassword} maxLength={'15'} onChange={(e) => {
                                setChangedPassword(e.target.value);
                            }}/>
                            {changedPassword === '' || checkPasswordRule(changedPassword) &&
                                <div style={{color: 'red', fontSize: '12px', marginBottom: '12px'}}>
                                    비밀번호는 8~15 자리 영문,숫자,특수문자를 포함해야 합니다. </div>}

                            <Text>비밀번호 확인</Text>
                            <Input readOnly={false} fulled type="password"
                                   value={checkChangedPassword} maxLength={'15'} onChange={(e) => {
                                setCheckChangedPassword(e.target.value);
                            }}/>
                            {changedPassword !== checkChangedPassword &&
                                <div style={{color: 'red', fontSize: '12px', marginBottom: '12px'}}>
                                    비밀번호가 일치하지 않습니다. </div>}
                        </Field>
                    </Board>


                </Organization>}

                <Modal
                    opend={opendNewTeam}
                    closeModal={() => {
                        setOpendNewTeam(modalClose.current);
                        modalClose.current = false;
                    }}
                    header={'부서 신규등록'}
                    okText={'확인'}
                    handleOk={handleNewTeam}
                    widthCheck={'50%'}
                >

                    <form className="new-team-form">
                        <Field style={{width: '100%'}}>
                            <Text>코드</Text>
                            <Input name="team_order" type="number" style={{width: '100%'}}/>
                        </Field>

                        <Field style={{width: '100%'}}>
                            <Text>부서명</Text>
                            <Input name="team_nm" style={{width: '100%'}}/>
                        </Field>
                    </form>

                </Modal>

                <Modal
                    opend={opendNewRank}
                    closeModal={() => {
                        setOpendNewRank(modalClose.current);
                        modalClose.current = false;
                    }}
                    header={'직급 신규등록'}
                    okText={'확인'}
                    handleOk={handleNewRank}
                    shouldCloseOnOverlayClick={false}
                    widthCheck={'50%'}
                >

                    <form className="new-rank-form">
                        <Field style={{width: '100%'}}>
                            <Text>코드</Text>
                            <Input name="rank_order" type="number" style={{width: '100%'}}/>
                        </Field>

                        <Field style={{width: '100%'}}>
                            <Text>직급명</Text>
                            <Input name="position_nm" style={{width: '100%'}}/>
                        </Field>
                    </form>
                </Modal>

                <PlanSelectModal
                    opend={openPlanSelect}
                    closeModal={() => {
                        setOpenPlanSelect(false);
                    }}

                    widthCheck={'50%'}
                    maxHeightCheck={'797'}
                >
                    <div>test</div>

                </PlanSelectModal>
                {/*전화번호 문자 인증*/}
                <Modal
                    opend={opendModifyPhone}
                    closeModal={() => {
                        setOpendModifyPhone(false);
                        //  modalClose.current = false;
                    }}
                    header={'휴대폰번호(관리자) 재인증'}
                    okText={'인증 완료'}
                    formRef={formRef}
                    //   handleOk={handleNewRank}
                    //  shouldCloseOnOverlayClick={false}
                    widthCheck={'500px'}
                >
                    <ModifyPhoneModal formRef={formRef} phoneNum={getValues('manager_phone_num')}
                                      handleResetPhone={handleResetPhone}></ModifyPhoneModal>

                </Modal>
            </div>
        </AxiosNavigate>
    );
}

const Tabs = styled.div`
    display: flex;
    padding: 0 32px;
    margin-bottom: 30px;
`;
const FieldButton = styled.div`
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    box-sizing: border-box;
    display: inline-block;
    padding: 8px;
    border-radius: 6px;
    background: #4A5568;
    // margin-left: 8px;
    cursor: pointer;
    text-align: center;

    ${props => props.disabled && `
  background: #CBD5E0;
  cursor: default;
`}
`;
const Tab = styled.div`
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    border-radius: 9999px;
    background: #4A5568;
    border: 1px solid #718096;
    margin-right: 16px;
    cursor: pointer;

    ${({selected}) => !selected ? `
background: #fff; color: #718096;
` : ''}


`;

const Form = styled.form`
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Field = styled.div`
    width: 100%;

    label {
        display: inline-block;
        padding-bottom: 12px;
    }

    @media only screen and (min-width: 992px) {
        width: 33.3%;
    }
`;

const Fields = styled.div`
    //display: flex;
    flex-wrap: wrap;
    ${({imgBox}) => imgBox === 'true' ? 'display:grid; column-gap: 20px;' : 'display:flex'};
    grid-template-columns: repeat(3, 1fr);
    //row-gap: 20px;

    > div {

    }

    @media only screen and (min-width: 992px) {
        > div {
            //width: 33.3%;
        }
    }

    .uploader__file_input_label {

    }

    .uploader__file_input_label > div {
        position: absolute;
    }
`;
const ImageBox = styled.div`
    width: 100%;
    display: block;
    padding-bottom: 100%;
    position: relative;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px dashed #CBD5E0;
    overflow: hidden;

    > * {
        position: absolute;
    }

    .uploader__file,
    .delete-label,
    .upload-label {
        display: none;
    }

    .uploader__container {
        width: 100%;
        height: 100%;
    }

    &:hover {
        .hover-wrapper {
            background: rgba(0, 0, 0, 0.30);
        }

        .uploader__file,
        .delete-label,
        .upload-label {
            display: block;
        }
    }



`;
const Input = styled.input`
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    appearance: none;
    box-sizing: border-box;
    width: 90%;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #FFF;
    border: 1px solid #CBD5E0;
    border-radius: 6px;
    margin-bottom: 12px;

    :focus {

    }

    &&:read-only {
        background-color: #dad9d9;
    }
`;

const Text = styled.span`
    color: #718096;
    font-size: 14px;
    font-style: normal;
    display: block;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;

    span {
        color: red;
    }
`;

const Card = styled.div`
    background: #F7FAFC;
    width: 100%;
    padding: 32px 24px 32px 40px;
    margin-top: 32px;
`;

const Title = styled.div`
    color: #2D3748;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
`;

const Button = styled.button`
    display: flex;
    height: 32px;
    padding: 1px 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3279F5;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-decoration: none;
    outline: none;
    border: none;
    cursor: pointer;
`;

const Icon = styled.img`
    margin-right: 6px;
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    // margin-top: 46px;
    //   margin-bottom: 20px;
    cursor: pointer;
`;

const Organization = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 32px;
    gap: 16px;
    margin-bottom: 40px;
`;
const Board = styled.div`
    width: 100%;

    @media only screen and (min-width: 992px) {
        width: 45%;
    }
`;

const BoardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TableContainer = styled.div`
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    //  border-right: none;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        padding: 8px;
        font-size: 13px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #F7FAFC;
    }

    tr:last-child td {
        border-bottom: none;
    }


    td:first-of-type {
        text-align: center;
    }

`;

const Td = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
`;

const TdButton = styled.div`
    display: flex;
    height: 24px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    border: 1px solid #E2E8F0;
    cursor: pointer;
`;

const ImagePreview = styled.div`
    display: flex;
    padding: 80px 0;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    justify-content: center;
    flex: 1 0 0;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    align-self: stretch;

    background: #FFF;
    //margin: 0 12px;
    cursor: pointer;

    img {
        top: 20%;
        position: absolute;

    }

    div {
        position: absolute;
        bottom: 20%;
    }
`;

const ImageWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 10%;
    z-index: 999;
`;

const ImageDelete = styled.div`
    position: absolute;
    top: 50%;
    right: 10%;
    z-index: 999;
`;

const ImageButton = styled.div`
    color: #2D3748;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    display: flex;
    height: 32px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #CBD5E0;
    background: #FFF;
    cursor: pointer;
    z-index: 999;
`;

const Uploader = styled.div`
    cursor: pointer;

    .hover-wrapper {
        width: 100%;
        height: 100%;
    }

`;

const ImageDefault = styled.img`
    // height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
`;

const Content = styled.div`
    margin: 16px 0;
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0 24px;
`;

const Badge = styled.div`
    //padding: 1px 6px;
    color: #fff;
    background: #3279F5;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    word-wrap: break-word;
    border-radius: 6px;
    align-content: center;
    text-align: center;
`;


export default SettingCompany;