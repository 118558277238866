import axiosInstance from './axios-index';

const token = localStorage.getItem('admin_token');

// admin 팝업 정보 조회
export const getPopupListAdmin = async (data) => {
    return await axiosInstance.post(`/admin/pop-up/get/list`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// admin 팝업 정보 생성
export const createPopupAdmin = async (data) => {
    return await axiosInstance.post(`/admin/pop-up/create`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// admin 팝업 정보 삭제
export const deletePopupAdmin = async (data) => {
    return await axiosInstance.post(`/admin/pop-up/delete`, {
        pop_seq: data
    }).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// admin 팝업 정보 수정
export const updatePopupAdmin = async (data) => {
    return await axiosInstance.post(`/admin/pop-up/update`, data).then((response) => {
        return response.data.result;
    }).catch(error => {
        throw error;
    });
};

// 노동자 개별 출역 정보(WEB)
export const getLaborerAttendance = async ({user_site_seq, site_seq, selectedDate}) => {
    return await axiosInstance.post(`/admin/get/laborer-attendance`, {
        user_site_seq: user_site_seq,
        site_seq: site_seq,
        work_dt: selectedDate
    }).then(({data: {result: result}}) => {
        return result;
    }).catch(({response: {data}}) => {
        alert(data.message);
    });
};
// /laborAttendanceModify

// 출역 일보 출역 이력 수정 및 생성 API
export const createLaborerAttendance = async (data) => {
    return await axiosInstance.post(`/admin/laborAttendanceModify`, data).then(({data: {result: result}}) => {
        return result;
    }).catch(({response: {data}}) => {
        alert(data.message);
    });
};

// 회사 목록 필터 어드민용
export const getCompanyFilter = async (filterType) => {
    return await axiosInstance.post(`/admin/get/company-filter`, {
        filterType: filterType
    }).then(({data: {result: result}}) => {
        return result;
    }).catch(({response: {data}}) => {
        alert(data.message);
    });
};

// 회원, 근무중 여부 확인 - 주민번호로 검색
export const getLaborSearchInfo = async (id_num) => {
    const data = {
        lang: 'ko',
        uuid: 'string',
        id_num: id_num,
        //     token
    };
    return await axiosInstance.post(`/admin/laborSearchInfo`, data).then(({data: {result: result}}) => {
        return result;
    }).catch(({response: {data}}) => {
        alert(data.message);
    });
};

// 근로자정보 - 주민번호, 회원순번으로 검색
export const getLaborInfo = async (data) => {
    const {id_num, user_seq} = data;
    return await axiosInstance.post(`/admin/laborInfo`, {
        lang: 'ko',
        uuid: 'string',
        id_num: id_num,
        user_seq: user_seq,
        //     token
    }).then(({data: {result: result}}) => {
        return result;
    }).catch(({response: {data}}) => {
        alert(data.message);
    });
};